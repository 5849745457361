.formWrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.loginForm {
    width: 400px;
}

.rememberWrapper {
    display: flex;
    justify-content: space-between;
}

.rememberWrapper a {
    text-decoration: underline;
}